﻿import React from 'react';
import { Description, Product } from './Product';
import './Products.css';

export function Products() {
    return (<div className="products">
        <Product id="fiskalizimi" header="Fiskalizimi" key={1} images={[""]}>
            <Description>
                <li><strong>Fiskalizimi</strong> eshte servisi me i ri qe shoqeron te gjitha produktet tona.</li>
            </Description>
        </Product>

        <Product id="hms" header="HMS" key={2} images={["hotel1.png", "hotel2.jpg", "hotel3.jpg", "hotel4.jpg"]}>
            <Description>
                <li><strong>Trinis HMS (Hotel Management System)</strong> eshte nje program i ndertuar per administrimin dhe menaxhimin hoteleve te te gjitha llojeve, duke filluar nga hotelet e vegjel, te cilet kane jo me shume se 10 dhoma, e deri tek resortet me 5 yje, me numer te pakufizuar dhomash, sallash, restorantesh e pika shitje/sherbimesh. Nepermjet nje nderfaqe te kendshme, te qarte dhe te thjeshte ne perdorim, mund te adminstrohet i gjithe hoteli, ose nje pjese e tij, te menaxhohen rezervimet, klientet, dhomat, sallat, restorantet e pikat e sherbimit, te gjitha te lidhura ne nje sistem global menaxhim/finance. Raportet financiare qe gjenerohen jane te pergjithshme dhe te detajuara, si dhe i jane pershtatur sistemit financiar shqiptar.</li>
                <li><strong>Administrimi Trinis HMS</strong> eshte nje program krejtesisht dinamik. Ai i pershtatet struktures se hotelit tuaj, ne menyre te thjeshte dhe efiçente. Brenda modulit administrues mund te krijohen dhomat, sallat, restorantet, magazinat, pikat e sherbimit dhe ato te shitjes, duke i emertuar me emrat dhe numrat perkates. Administrimi gjithashtu perfshin edhe detajet e dhomave dhe sallave, duke specifikuar ne to, qe tek modeli i dhomes, oferta financiare, e deri tek aksesoret. Vendosja e dhomave ne nderfaqe eshte gjithashtu dinamike, duke krijuar mundesine e krijimit te hartes se hotelit, e cila lehteson punen, si nga ana vizive, ashtu edhe nga ana praktike. Administrimi perfshin dhe vendosjen e statusit te dhomave, qe krijon mundesi per nxjerrjen e dhomave jashte sherbimit, pastrimin, etj. Brenda te njejtes nderfaqe, administratori merr te dhena ne kohe reale per rezervimet, okupancen, klientet, gjendjen e dhomave, personelin etj.</li>
                <li><strong>Perdoruesit Trinis HMS</strong> funksionon ne baze te nje sistemi sigurie, ku cdo anetar i personelit perfaqeson nje perdorues. Hierarkia e perdoruesve krijohet nepermjet te drejtave specifike, te cilat i jepen cdo perdoruesi nga administratori i hotelit. Menaxhimi i perdoruesve (personelit) perfshin te gjitha detajet, duke filluar nga gjeneralitetet, fotografia, te dhenat personale, rrogen mujore si dhe te drejtat per te perdorur pjese te ndryshme te programit. Cdo perdoruesi i percaktohen te drejtat, ne menyre qe ai te perdore vetem ato pjese te programit qe i nevojiten. Ky sistem eshte fleksibel dhe i sigurt, pasi pjeset sensitive te programit funksionojne vetem per perdorues te caktuar nga administratori.</li>
                <li><strong>Recepsioni dhe Sistemi i rezervimeve</strong> Trinis HMS menaxhon dhomat dhe sallat nepermjet nje moduli te vecante, i cili vendoset ne recepsionin e hotelit. Nderfaqja grafike edhe ne kete rast ruan formen e nje harte te dhomave, e cila percaktohet sipas deshires dhe nevojave. Proceset e rezervimit, check-in dhe check-out realizohen me fare pak klikime te “mouse”-it. Sistemi ka nje autokontroll, i cili nuk lejon rezervimin e te njejtes dhome ne te njejten kohe nga dy kliente te ndryshem, si dhe perjashton nga rezervimet dhomat qe jane jashte sherbimit per arsye te ndryshme. Per cdo dhome raportohen te gjitha detajet e saj, duke filluar qe nga statusi i saj, aksesoret dhe datat e rezervimeve. Brenda ketij sistemi perfshihen dhe mesazhet qe lihen per klientet, porosite e vecante, oraret e zgjimit, parkingu per automjetet etj. Gjithashtu brenda ketij sistemi realizohet dhe shtypja e fatures, duke bere dhe ndarjen e saj ne pjese sipas kerkeses se klientit.</li>
                <li><strong>Lidhja me Restorantet dhe Pikat e Sherbimit Trinis HMS</strong> eshte i vetmi program i llojit te tij, i cili krijon mundesine e lidhjes teknike dhe financiare midis dhomave, restoranteve dhe pikave te sherbimit. Nje fature e restorantit mund te kaloje direkt ne dhome apo ne nje restorant tjeter, duke i krijuar mundesine klientit te paguaje nje fature globale. I njejti proces vlen gjithashtu edhe per pikat e sherbimit. Kalimi i faturave nga nje njesi e hotelit ne nje tjeter eshte nje proces shume i nevojshem, pasi shpesh ka kliente, te cilat shume prej pagesave i bejne ne momentin e check-out. Ky proces kalimi eshte i pasqyruar ne menyre te detajuar ne raportet financiare.</li>
                <li><strong>Klientet Trinis HMS</strong> ruan arkivin e klienteve, i cili pasurohet gjate procesit te punes. Ky sistem krijon shpejtesi dhe lehtesi ne perdorim, pasi per cdo klient, grup klientesh ose kompani mund te krijohen oferta speciale. Ky sistem perfshin edhe agjentet prenotues te cilet sigurojne kliente per hotelin. Gjithashtu ky sistem gjeneron raporte te detajuara mbi klientet dhe agjentet prenotues per periudha te specifikuara kohore. Nepermjet ketyre raporteve mund te nxirren klientet me frekuente apo agjentet qe i kane sjelle me teper te ardhura hotelit.</li>
                <li><strong>Financa Trinis HMS</strong> gjeneron raporte financiare globale dhe specifike. Raportet globale financiare perfshijne te gjitha njesite e hotelit duke nxjerre rezultatin e pergjithshem financiar. Raportet specifike gjenerohen per njesi te vecanta (restorante, pika sherbimi, magazina, etj) ose per qellime specifike (okupanca, shitja e mallrave, furnizimet, klientet, etj). Raportet ndertohen per periudha kohore sipas specifikimeve qe i jep perdoruesi. Cdo raport mund te shtypet ne leter, te perdoret si dokument financiar ose statistikor. Shume prej raporteve permbajne grafike, te cilet lehtesojne analizen e tyre.</li>
            </Description>
        </Product>

        <Product id="bar" header="Bar & Restorant" key={3} images={["bar1.jpg", "bar2.jpg"]}>
            <Description>
                <li><strong>Trinis Bar/Restorant</strong> eshte nje program I ndertuar per administrimin dhe menaxhimin e te gjitha llojeve te Bareve dhe Restoranteve. Me nje nderfaqe te qarte, komode ne perdorim dhe nga me bashkekohoret, ju mund te administroni te githe Bar/Restorant-in duke filluar qe nga tavolinat, artikujt, produktet, personelin dhe deri te klientet tuaj. Ky program ju mundeson historikun e detajuar te cdo veprimi qe kryhet nepermejet raporteve te shumta qe ofron per Bar/Restorantin tuaj.</li>
                <li><strong>Administrimi Trinis Bar/Restorant</strong>  eshte nje program krejtesisht dinamik. Ai I pershtatet mese miri struktures se Bar/Restorant-it tuaj, ne menyren me te thjesht dhe efikase, duke ju ofruar komoditetin me te larte ne administrim. Brenda modulit administrues mund te krijohen perdoruesit (personeli), seksione (kuzhina, banaku etj), sikurse dhe informacionin e detajuar te Bar/Restorant-it tuaj, duke I emertuar me emrat dhe numrat perkates. Vendosja e tavolinave ne ndefaqe eshte gjithashtu dinamike, duke pasur mundesine e vendosjes se tavolinave sipas hartes se Bar/Restorant-it tuaj, paraqet nje komoditet si nga ana vizive, ashtu dhe nga ana praktike ne punen tuaj.</li>
                <li><strong>Perdoruesit Trinis Bar/Restorant</strong>  funksionon ne baze te nje sistemi sigurie, ku cdo anetar i personelit perfaqeson nje perdorues. Hierarkia e perdoruesve krijohet nepermjet te drejtave specifike, te cilat i jepen cdo perdoruesi nga administratori i Bar/Restorant-it. Menaxhimi i perdoruesve (personelit) perfshin te gjitha detajet, duke filluar nga gjeneralitetet, fotografia, te dhenat personale, rrogen mujore si dhe te drejtat per te perdorur pjese te ndryshme te programit. Cdo perdoruesi i percaktohen te drejtat, ne menyre qe ai te perdore vetem ato pjese te programit qe i nevojiten. Ky sistem eshte fleksibel dhe i sigurt, pasi pjeset sensitive te programit funksionojne vetem per perdorues te caktuar nga administratori.</li>
                <li><strong>Raportet Trinis Bar/Restorant</strong>  ofron dhe raportin e detajuar te cdo veprimi qe kryhet ne Bar/Restorant-in tuaj. Cdo veprim, funizim apo shitje, te cdo artikulli apo produkti, do t’ju paraqitet i detajuar ne programin tone duke ju dhene mundesine te shikoni hap pas hapi ecurine e Bar/Restorant-it tuaj. Rubrika e Raporteve ju mundeson edhe menaxhimin e menuve, ku ju mund te kategorizoni cdo produkt sipas preferences suaj.</li>
                <li><strong>Klientet Trinis Bar/Restorant</strong>  ruan arkivin e klienteve, i cili pasurohet gjate procesit te punes. Ky sistem krijon shpejtesi dhe lehtesi ne perdorim, pasi per cdo klient apo grup klientesh mund te krijohen oferta speciale. Gjithashtu ky sistem gjeneron raporte te detajuara mbi klientet per periudha te specifikuara kohore. Nepermjet ketyre raporteve mund te nxirren klientet me frekuente qe i kane sjelle me teper te ardhura Bar/Restorant-it.</li>
            </Description>
        </Product>

        <Product id="pos" header="POS" key={4} images={["pos1.jpg"]}>
            <Description>
                <li><strong>Trinis POS</strong> eshte nje program I ndertuar per administrimin dhe menaxhimin e Pikes se Shitjes (Dyqanit), me nje larmi opsionesh ky program vjen te ju me nje cmim mjaft konkurues ne treg. Nepermjet nje nderfaqe te kendshme, te qarte dhe te thjeshte ne perdorim, mund te adminstrohet i gjithe dyqani, si magazinat, klientet, furnitoret si dhe perdoruesit (personeli).</li>
                <li><strong>Administrimi Trinis POS</strong> eshte nje program krejtesisht dinamik. Ai I pershtatet mese miri struktures se POS-it (Dyqanit) tuaj, ne menyren me te thjesht dhe efikase, duke ju ofruar komoditetin me te larte ne administrim. Brenda modulit administrues mund te krijohen perdoruesit (personeli), sikurse dhe informacionin e detajuar te POS-it tuaj, duke I emertuar me emrat dhe numrat perkates.</li>
                <li><strong>Raportet Trinis POS</strong> ofron dhe raportin e detajuar te cdo veprimi qe kryhet ne POS-in (Dyqanin) tuaj. Cdo veprim, funizim apo shitje, te cdo artikulli do t’ju paraqitet i detajuar ne programin tone duke ju dhene mundesine te shikoni hap pas hapi ecurine e biznesit tuaj.</li>
                <li><strong>Klientet Trinis POS</strong> ruan arkivin e klienteve, i cili pasurohet gjate procesit te punes. Ky sistem krijon shpejtesi dhe lehtesi ne perdorim. Gjithashtu ky sistem gjeneron raporte te detajuara mbi klientet per periudha te specifikuara kohore. Nepermjet ketyre raporteve mund te nxirren klientet me frekuente qe i kane sjelle me teper te ardhura POS-it.</li>
            </Description>
        </Product>

        <Product id="kontabel" header="Kontabel" key={5} images={["kontabel1.jpg", "kontabel2.png", "kontabel3.jpg"]}>
            <Description>
                <li><strong>Trinis Kontabel</strong> eshte nje program I ndertuar per administrimin, menaxhimin si dhe raporte globale ose specifike financiare. Me nje nderfaqe te qarte, komode ne perdorim dhe nga me bashkekohoret, ju mund te nxirrni nje raport financiar te pergjithshem per ecurine e cdo lloj biznesi qe ju dispononi ose menaxhoni.</li>
                <li><strong>Perdoruesit Trinis Kontabel</strong> funksionon ne baze te nje sistemi sigurie, ku cdo anetar i personelit perfaqeson nje perdorues. Hierarkia e perdoruesve krijohet nepermjet te drejtave specifike, te cilat i jepen cdo perdoruesi nga administratori . Menaxhimi i perdoruesve (personelit) perfshin te gjitha detajet, duke filluar nga gjeneralitetet, fotografia, te dhenat personale, rrogen mujore si dhe te drejtat per te perdorur pjese te ndryshme te programit. Cdo perdoruesi i percaktohen te drejtat, ne menyre qe ai te perdore vetem ato pjese te programit qe i nevojiten. Ky sistem eshte fleksibel dhe i sigurt, pasi pjeset sensitive te programit funksionojne vetem per perdorues te caktuar nga administratori.</li>
                <li><strong>Financa Raportet</strong> specifike gjenerohen per njesi te vecanta (restorante, pika sherbimi, magazina, etj) ose per qellime specifike (okupanca, shitja e mallrave, furnizimet, klientet, etj). Raportet ndertohen per periudhsa kohore sipas specifikimeve qe i jep perdoruesi. Cdo raport mund te shtypet ne leter, te perdoret si dokument financiar ose statistikor. Shume prej raporteve permbajne grafike, te cilet lehtesojne analizen e tyre.</li>
            </Description>
        </Product>
    </div >);
}