﻿import React from 'react';
import './Partner.css';

export function Partners() {
    return (
        <div className="partners">
            <div className="holderRight">
                <h1 className="display-5 mb-3">Ne punojme me partneret me te mire</h1>
                <p>
                    <strong>Synimi kryesor i Trinissoft</strong> eshte lehtesimi i punes per perdoruesin e thjeshte. Kjo realizohet nepermjet nderfaqeve teresisht ne gjuhen shqipe. Programet e Trinissoft jane te perdorshme edhe per ata persona qe nuk kane asnje lloj kualifikimi ne fushen e kompjuterave. Ne ofrojme instalime specifike te sistemeve operative (Windows XP, 2003), duke lehtesuar, si punen e operatoreve, ashtu edhe te mirembajtesave te rrjeteve apo te kompjuterave.
                </p>
            </div>
            <div className="holderLeft">
                <p>Hotel Mondial</p>
                <p>Hotel Grand</p>
                <p>Hotel Continental</p>
                <p>Hotel Adriatik</p>
                <p>Hotel Tropikal</p>
                <p>Hotel Turizmi</p>
                <p>Hotel Class</p>
                <p>Hotel Cottone</p>
                <p>Hotel VilaAlba</p>
                <p>Hotel Partner</p>
                <p>Hotel D'Oro</p>
                <p>Hotel de Paris</p>
                <p>Hotel Harmonia HG</p>
                <p>Hotel Diplomat HY</p>
                <p>Hotel Diplomat Fashion</p>
                <p>Chateau Linza</p>
                <p>Natyral Razma Resort</p>
                <p>Caffe de Paris</p>
                <p>Mak Albania Golem</p>
                <p>Bonapart</p>
                <p>Mumja</p>
                <p>Viktoria</p>
                <p>Milenium</p>
                <p>Tiki Bar</p>
                <p>Segafredo</p>
                <p>Pendium</p>
                <p>Caramel</p>
                <p>AdriBeauty</p>
                <p>2acPharma</p>
            </div>
       </div>
    );
}
