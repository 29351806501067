import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './menu/NavMenu';
import { Footer } from './footer/Footer';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div>
                <NavMenu />
                <div className="spacer"/>
                {this.props.children}
                <Footer />
            </div>
        );
    }
}
