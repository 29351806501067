import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/home/Home";
import { Partners } from "./components/partners/Partner";
import { Products } from "./components/products/Products";
import { Contacts } from "./components/contacts/Contacts";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/partner',
        element: <Partners />
    },
    {
        path: '/products',
        element: <Products />
    },
    {
        path: '/contacts',
        element: <Contacts />
    },
    {
        path: '/fetch-data',
        element: <FetchData />
    }
];

export default AppRoutes;
