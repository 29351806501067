﻿import React, { Component, useState } from 'react';
import { Carousel, CarouselCaption, CarouselControl, CarouselIndicators, CarouselItem } from 'reactstrap';
import './Product.css';

interface IState {
    activeIndex: number
}

interface IProps {
    id: string,
    header: string,
    images: string[],
    children: React.ReactElement<Description>
}

interface IImage {
    src: string,
    altText: string,
    caption: string,
}

export class Product extends Component<IProps, IState> {
    private animating: boolean = false;
    private images: IImage[] = [];

    constructor(props: IProps) {
        super(props);
        this.state = { activeIndex: 0 };
        props.images.forEach((image: string) => {
            this.images.push({
                altText: '', caption: '', src: window.location.origin + '/img/' + image
            });
        })
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.images.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.images.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    render() {
        const { activeIndex } = this.state;

        const slides = this.images.map((item) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.src}
                >
                    <img src={item.src} alt={item.altText} />
                    <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
                </CarouselItem>
            );
        });

        return (
            <div id={this.props.id} className={`product`}>
                <h2>{this.props.header}</h2>
                <Carousel
                    activeIndex={activeIndex}
                    next={this.next}
                    previous={this.previous}
                >
                    <CarouselIndicators items={this.images} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                    {slides}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                </Carousel>
                <div className="desc">
                    {this.props.children}
                </div>
            </div>

        );
    }
}

interface IPropsDescription {
    children: React.ReactElement<HTMLLIElement> | React.ReactElement<HTMLLIElement>[]

}

export class Description extends Component<IPropsDescription, {}> {
    render() {
        return (<ul>
            {this.props.children}
        </ul>);
    }
}