﻿import React from 'react';
import './Contacts.css';

export function Contacts() {
    return (<div className="contacts">
        <h1 className="display-5 mb-3">Kontakt</h1>
        <div><strong>Per cdo problem qe mund te keni me programet e TrinisSoft</strong>, ju mund te kontaktoni Sherbimin e kujdesit ndaj klientit.
            TrinisSoft ve ne sherbimin tuaj adresen tone te e-mail-it :</div>
        <br />
        <ul>
            <li> <strong>Informacioni :</strong> info@trinissoft.com</li>
            <li> <strong>Tel / Mob    :</strong> +355 69 40 20360</li>
        </ul>
        <br />
        <div>Ku personeli jonë do t’ju gjendet prane per cdo problem qe keni, duke u munduar t’ju jape nje zgjidhje sa me te shpejte dhe efikase. Ne kete adrese ju mund te jepni sugjerimet dhe verejtet tuaja.
            Cdo kontakt do te jete i mirpritur, TinisSoft do t’ju gjendet gjithmone prane. Ju Faleminderit qe zgjodhet TrinisSoft.</div>

    </div>);
}
