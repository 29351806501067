﻿import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import './Footer.css';

export function Footer() {
    return (
        <footer className="footer text-center text-lg-start">
            <section className="">
                <div className="container text-center text-md-start mt-5">
                    <div className="row mt-3">
                        <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                            <h1 className="text-uppercase fw-bold mb-4">
                                <img src="https://trinissoft.com/image/logo1.png"></img>
                            </h1>
                        </div>
                        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">
                                Produkte
                            </h6>
                            <p>
                                <Link to="/products#fiskalizimi">Fiskalizimi</Link>
                            </p>
                            <p>
                                <Link to="/products#hms">HMS</Link>
                            </p>
                            <p>
                                <Link to="/products#bar">Bar & Restorant</Link>
                            </p>
                            <p>
                                <Link to="/products#pos">POS</Link>
                            </p>
                            <p>
                                <Link to="/products#kontabel">Kontabel</Link>
                            </p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">Kontakte</h6>
                            <p><i className="fas fa-home me-3"></i>
                                <a href="mailto:informacion@trinissoft.com">info@trinissoft.com</a>
                            </p>
  
                            <p><i className="fas fa-print me-3"></i>+355 69 40 20360</p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="text-center p-4">
                © { new Date().getFullYear() } Copyright: TrinisSoft
            </div>
        </footer>
    );
}
